<template>
  <div class="staticLayer">
    <!-- <a class="close-btn" href="javascript:;" @click.stop="closeView">×</a> -->
    <div class="main" :style="[
      {width: json.width ? json.width : '600px'}, 
      {height: json.height ? json.height : '350px'}, 
      {right: json.position == 'right' ?  '50px' : '0'}

      ]">

      <!-- <div style="position: relative;"> -->
      <div class="main_closeBtn" @click.stop="closeView">关闭</div>

      <!-- </div> -->
      <!-- <img class="close-btn" src="@/assets/images/common/icon_close.png" alt="" style="width: 32px; height: 32px;" href="javascript:;"  @click.stop="closeView">-->
      <img src="@/assets/images/common/icon_adv.png" alt="" style="width: 100%; height: 100%;"> 
    </div>

  </div>
</template>
<script>
export default {
  name: 'StaticAdv',
  data() {
    return {}
  },
  props: ['json'],
  mounted() {
    // this.init(this.json)
  },
  methods: {
    closeView() {
      this.$emit('closeView')
    }
  }
}
</script>
<style>
.staticLayer {
  position: fixed;
  right: 0;
  bottom: 0;
}
.staticLayer .main {
  position: relative;
}
.staticLayer .main .main_closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  letter-spacing: 2px;
  color: #fff;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
/* .close-btn:hover {
  color: #b50900;
  background: #ccc;
} */
.floatLayer img {
  /* position: relative; */
  z-index: 101;
}
</style>