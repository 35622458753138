import router from '@/router'

/**
 * 路由跳转方法
 * @param {object} query 参数
 * @param {boolean} cache 是否缓存(true缓存)
 * @param {string} routerType 调整类型
 */
export const naviGate = (name, query = {}, cache = false, routerType = 'push') => {
  if (cache) {
    router[routerType]({ name, params: query })
  } else {
    router[routerType]({ name, query })
  }
}

export const delay = (fn, time = 300) => {
  const timeout = setTimeout(() => {
    fn()
    clearTimeout(timeout)
  }, time)
}

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}
/**
 * 数据格式转换（list to tree）
 * @param {Array} list 数据源
 * @param {string} idName 节点ID字段名
 * @param {string} parentName 父节点字段名
 * @param {string} childName 子节点字段名
 */
export const listToTree = (list, idName, parentName, childName, id = null) => {
  let ret = null
  if (id == null) {
    ret = []
    if (list == null) {
      return ret
    }
    list.forEach(function (item) {
      if (item[parentName] === undefined || item[parentName] === 0) {
        var node = JSON.parse(JSON.stringify(item))
        var childs = listToTree(
          list,
          idName,
          parentName,
          childName,
          item[idName]
        )
        if (childs.length > 0) {
          node[childName] = childs
        }
        ret.push(node)
      }
    })
    return ret
  } else {
    ret = []
    list.forEach(function (item) {
      if (item[parentName] !== undefined && item[parentName] === id) {
        var node = JSON.parse(JSON.stringify(item))
        var childs = listToTree(
          list,
          idName,
          parentName,
          childName,
          item[idName]
        )
        if (childs.length > 0) {
          node[childName] = childs
        }
        ret.push(node)
      }
    })
    return ret
  }
}

/**
 * @description: 通过子节点id获取该树分支中的所有父节点数据
 * @param {array} arr 菜单数据
 * @param {number} id 菜单id
 * @return {array} temp 本菜单的所有父级菜单
 */
export const getTreeMenu = (arr, id) => {
  const temp = []
  const callback = function (nowArr, id) {
    for (let i = 0; i < nowArr.length; i++) {
      let item = nowArr[i]
      if (item.id === id) {
        temp.push(item)
        callback(arr, item.categoryId)
        break
      } else {
        if (item.child) {
          callback(item.child, id)
        }
      }
    }
  }
  callback(arr, id)
  return temp
}

/**
 * 时间戳转日期对象,默认当前日期
 * @param {number}  stamp 时间戳
 */
export const dateObj = (stamp = new Date()) => {
  if (typeof stamp == 'string') stamp = Number(stamp)
  if (typeof stamp == 'string' || typeof stamp == 'number') {
    stamp = new Date((stamp + '').length == 10 ? stamp * 1000 : stamp)
  }
  const date = new Date(stamp)
  const year = date.getFullYear() + ''
  const month =
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds
  }
}

/**
 * 获取设备类型
 * @return { boolean }  facility:true移动端；反之PC端
 */
export const getDeviceType = () => {
  const userAgent = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'NokiaN9',
    'SymbianOS',
    'Windows Phone',
    'iPad'
  ]
  const facility = Agents.some((i) => {
    return userAgent.includes(i)

    // 本项目暂无移动端 固定未pc端
    // return false
  })
  return facility
}

/**
 * 服务器资源-可能是本地服务器的相对路径，也可能是云服务器的绝对路径，统一处理
 * @return { string } url 文件路径
 */
export const convertImgUrl = (url) => {
  if (!url) return ''
  if (
    url &&
    url.length > 8 &&
    (url.substring(0, 7) == 'http://' || url.substring(0, 8) == 'https://')
  ) {
    return url
  } else {
    let frontPath = process.env.VUE_APP_PIC_ROOT || process.env.VUE_APP_BASE_URL
    return frontPath + url
  }
}
