import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.timeout = 3000 // 响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8' // 配置请求头

// 传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做某件事
    return config
  },
  (error) => {
    console.log('错误的传参')
    return Promise.reject(error)
  }
)

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (response) => {
    // 对响应数据做些事
    switch (response.data.errCode) {
      default:
        break
    }
    return response.data
  },
  (error) => {
    return Promise.reject(error.response)
  }
)
/**
 * 入参格式化(传参删除空值)
 */
function formatParams(param) {
  Object.keys(param).forEach((item) => {
    if (
      param[item] === '' ||
      param[item] === null ||
      param[item] === undefined
    ) {
      delete param[item]
    }
  })
  return param
}
/**
 * Promise(发送post请求)
 */
function post(url, params) {
  formatParams(params)
  return new Promise((resolve, reject) => {
    /* 目前无需转换类型 qs.stringify(params) */
    axios
      .post(url, params)
      .then(
        (response) => {
          resolve(response)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Promise(发送get请求)
 */
function get(url, param) {
  formatParams(param)
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: param
      })
      .then(
        (response) => {
          resolve(response)
        },
        (err) => {
          reject(err)
        }
      )
      .catch((error) => {
        reject(error)
      })
  })
}

export default {
  get,
  post
}
