<template>
  <div class="maskLayer" :style="[{backgroundColor: getOpacity(json.opacity)}]">
    <!-- <a class="close-btn" href="javascript:;" @click.stop="closeView">×</a> -->
    <div class="main" :style="[{width: json.width ? json.width + 'px' : '600px'}, {height: json.height ? json.height + 'px'  : '350px'}, {borderRadius: getBorderRadius(json.fillet)}]">
      <div style="position: relative;" @click="skipExternalLinks(json.linkUrl)">
        <img v-if="json.advertType == 1" :src="handleUrl(json.advertContent)" alt="" style="width: 100%; height: 100%;">
        <div v-else class="htmlCss">
          <div v-html="json.advertContent" :style="{height: json.height ? json.height + 'px'  : '350px'}"></div>
        </div>
        <div class="main_closeBtn" :style="{borderTopRightRadius: getBorderRadius(json.fillet, 'btn')}" @click.stop="closeView">关闭</div>
      </div>
    </div>

  </div>
</template>
<script>
import { convertImgUrl } from '@/utils/index'
export default {
  name: 'MaskAdv',
  data() {
    return {}
  },
  props: ['json'],
  mounted() {
    // 广告持续时间
    if (this.json.displayTime) {
      this.countDown(this.json.displayTime)
    }
  },
  methods: {
    closeView() {
      this.$emit('closeView', { id: this.json.id })
    },
    /**
     * @description: 跳转外部链接
     * @param {*} url 外部链接地址
     */
    skipExternalLinks(url) {
      console.log('新窗口打开url', url);
      window.open(url, '_blank')
    },

    countDown(time) {
      // 开始倒计时
      var time_lang = time
      var timeOver = setInterval(() => {
        --time_lang
        if (time_lang > 0) {
        } else {
          // 倒计时小于0
          time_lang = time
          clearInterval(timeOver)
          this.closeView()
        }
      }, 1000)
    },

    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },

    /**
     * @description: 获取透明度
     * @param {*} opacity
     */
    getOpacity(opacity) {
      if (opacity) {
        return `rgba(0, 0, 0, ${opacity})`
      } else {
        return `rgba(0, 0, 0, 0.4)`
      }
    },

    /**
     * @description: 获取圆角
     * @param {*} borderRadius
     * @param {*} type btn-是否是关闭按钮
     */    
    getBorderRadius(borderRadius, type) {
      if (borderRadius) {
        return borderRadius.indexOf('%' != -1) ? (type == 'btn' ? '' : borderRadius) : borderRadius + 'px'
      } else {
        return ''
      }
    }
  }
}
</script>
<style>
.maskLayer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}
.maskLayer .main {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.maskLayer .main .main_closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  /* letter-spacing: 2px; */
  color: #fff;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
/* .close-btn:hover {
  color: #b50900;
  background: #ccc;
} */
.floatLayer img {
  /* position: relative; */
  z-index: 101;
}
.htmlCss {
  /* width: 100%;*/
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.htmlCss img,
p,
span {
  max-width: 100% !important;
}
</style>