import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { listToTree } from '@/utils/index'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    siteInfo: null,
    allMenuList: null,
    categoryList: null,
    isPc: null,
    isOpen: false
  },
  mutations: {
    setSiteInfo(state, value) {
      state.siteInfo = value
    },
    setAllMenuList(state, value) {
      state.allMenuList = value
    },
    setCategoryList(state, value) {
      state.categoryList = value
    },
    setFacility(state, value = true) {
      state.isPc = value
    },
    setSideBarStatus(state, value = false) {
      state.isOpen = value
    }
  },
  getters: {
    getCategoryList: (state) => state.categoryList,
    getSiteInfo: (state) => state.siteInfo,
    menuList: ({ categoryList }) =>
      listToTree(categoryList, 'id', 'categoryId', 'child').filter(
        (item) => item.isMenu
      )
  },
  actions: {},
  modules: {}
})
