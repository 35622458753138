/* rem等比适配配置文件 */
import { getDeviceType } from './index'

const baseSize = 100 // 基准大小 旧版本浏览器不支持12px以下的设置 不能设置为10 故将比例设置为100

/* 设置 rem 函数 */
function setRem() {
  /* 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。 */

  const facility = getDeviceType()

  let scaleWidth = 1920
  if (facility) {
    // wap端
    scaleWidth = 375
  } else {
    // pc端
    scaleWidth = 1920
  }

  const scale = document.documentElement.clientWidth / scaleWidth

  /* 设置页面根节点字体大小（Math.min(scale, 2) 指最高放大比例为2，可根据实际业务需求调整） */
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}

/* 初始化 */
setRem()

/* 改变窗口大小时重新设置 rem */
window.onresize = function () {
  setRem()
}
/* onresize 事件绑定多个方法 */
export const addREvent = (fn) => {
  const oldOnresize = window.onresize
  if (typeof window.onresize !== 'function') {
    window.onresize = fn
  } else {
    window.onresize = function () {
      oldOnresize()
      fn()
    }
  }
}
