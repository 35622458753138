import Vue from 'vue'
import VueRouter from 'vue-router'
// import my from './my'
import common from '../views/common.vue'
import index from '../views/index/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: common,
    children: [
      {
        path: 'index',
        component: index,
        name: 'index',
        meta: {
          title: '首页',
          requireAuth: true
        }
      },
      {
        path: '/list',
        name: 'list',
        // route level code-splitting
        // this generates a separate chunk (list.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "list" */ '../views/list.vue'),
        meta: {
          title: '列表页',
          requireAuth: true
        }
      },
      {
        path: '/detail',
        name: 'detail',
        component: () =>
          import(/* webpackChunkName: "detail" */ '../views/detail.vue'),
        meta: {
          title: '详情页',
          requireAuth: true
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () =>
          import(/* webpackChunkName: "search" */ '../views/search.vue'),
        meta: {
          title: '结果页',
          requireAuth: true
        }
      }
      // ...my
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    return { x: 0, y: 0 }
  }
})

export default router
