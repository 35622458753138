<!--
 * @Module 头部header组件
 -->

<template>
  <div style="position: relative">
    <!-- PC端展示内容 -->
    <!-- v-if="isPc" -->
    <div class="header">
      <div class="header_box">
        <div class="header_bg">
          <div class="header_mask" @click="toHomePage">
            <img class="logo" src="@/assets/images/pc/logo_text.png" alt="" />
            <!-- PC -->
            <img
              v-if="isPc"
              class="slogan"
              src="@/assets/images/pc/slogan.png"
              alt=""
            />
            <!-- 移动端 -->
            <template v-if="!isPc">
              <img
                class="icon_search"
                src="@/assets/images/mobile/icon_search.png"
                alt=""
                @click.stop="changeSearchFlag()"
              />
              <img
                v-if="isOpen"
                class="icon_menu"
                src="@/assets/images/mobile/icon_menu_close.png"
                alt=""
                @click.stop="showDrawer(false)"
              />
              <img
                v-else
                class="icon_menu"
                src="@/assets/images/mobile/icon_menu.png"
                alt=""
                @click.stop="showDrawer(true)"
              />
            </template>
          </div>

          <div v-if="isPc" class="header_search">
            <input
              type="text"
              @keyup.enter="submit"
              v-model="searchVal"
              placeholder=""
            />
            <div @click="submit">
              <img src="~@/assets/images/common/icon_search.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSearch" class="wap_search">
      <input
        type="text"
        @keyup.enter="submit"
        v-model="searchVal"
        placeholder="请输入关键词"
      />
      <div @click.stop="submit" class="wap_search_icon">
        <img src="@/assets/images/mobile/icon_search_blue.png" alt="" />
      </div>
    </div>
    <!-- wap端展示内容 -->
    <!-- <div v-else class="header-wap"></div> -->
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
export default {
  name: 'Header',
  data() {
    return {
      searchVal: '',
      isSearch: false
    }
  },
  created() {
    this.$root.eventBus.$on('searchVal', (res) => {
      if (res) {
        this.searchVal = res.searchVal
      }
    })
  },
  methods: {
    /**
     * @description: 跳转首页
     */
    toHomePage() {
      this.naviGate('index', { t: new Date().getTime() })
    },
    submit() {
      this.naviGate('search', {
        t: new Date().getTime(),
        search: this.searchVal
      })
      this.isSearch = false
    },
    /**
     * 打开wap端侧边栏
     */
    // showDrawer() {
    //   this.$root.eventBus.$emit('showDrawer', {
    //     drawer: true
    //   })
    // }
    showDrawer(bool) {
      this.$store.commit('setSideBarStatus', bool)

      this.$root.eventBus.$emit('showDrawer', {
        drawer: bool
      })
      this.isSearch = false
    },

    changeSearchFlag() {
      this.searchVal = ''
      this.isSearch = !this.isSearch
    }
  },
  computed: {
    ...mapState(['isPc', 'isOpen'])
  }
}
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 130px;
  @media screen and (max-width: 820px) {
    height: 0.56rem;
    overflow-x: hidden;
  }
  &_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    justify-content: center;
    background: url('~@/assets/images/pc/header_bg.png') no-repeat;
    background-size: auto 100%;
    background-color: #0e58ae;
    .header_bg {
      width: 1280px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 auto;
      @media screen and (max-width: 820px) {
        width: 100%;
        background: #0e59ae;
      }
      .header_mask {
        display: flex;
        align-items: center;
        height: 100%;
        // flex-shrink: 0;

        .logo {
          width: 354px;
          height: 60px;
          @media screen and (max-width: 820px) {
            width: 2.36rem;
            height: 0.4rem;
            margin-left: 0.15rem;
            margin: 0 0.44rem 0 0.15rem;
          }
        }

        .slogan {
          width: 256px;
          height: auto;
          align-self: center;
          margin-left: 60px;
          @media screen and (max-width: 820px) {
            display: none;
          }
        }
        .icon_search,
        .icon_menu {
          width: 0.2rem;
          height: 0.2rem;
          text-align: right;
        }
        .icon_menu {
          margin: 0 0.2rem;
        }
      }

      .header_search {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ::-webkit-input-placeholder {
          font-size: 15px;
          color: #888888 !important;
        } /* 使用webkit内核的浏览器 */

        :-moz-placeholder {
          font-size: 15px;
          color: #888888 !important;
        } /* Firefox版本4-18 */

        ::-moz-placeholder {
          font-size: 15px;
          color: #888888 !important;
        } /* Firefox版本19+ */

        :-ms-input-placeholder {
          font-size: 15px;
          color: #888888 !important;
        } /* IE浏览器 */

        input {
          width: 160px;
          height: 32px;
          padding: 2px 5px 2px 10px;
          background: #ffffff;
          font-size: 15px;
          border-radius: 2px 0px 0px 2px;
          border: none;
        }

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 32px;
          background: #409eff;
          margin-left: -1px;
          cursor: pointer;

          img {
            display: block;
            cursor: pointer;
            border-radius: 0px 2px 2px 0px;
          }
        }
      }
    }
  }
}
.wap_search {
  position: absolute;
  top: 0.55rem;
  z-index: 999;
  height: 0.56rem;
  width: 100%;
  background: #024b9d;
  padding: 0.12rem 0.2rem;
  display: flex;
  ::-webkit-input-placeholder {
    font-size: 0.14rem;
    color: 999999 !important;
  } /* 使用webkit内核的浏览器 */

  :-moz-placeholder {
    font-size: 0.14rem;
    color: 999999 !important;
  } /* Firefox版本4-18 */

  ::-moz-placeholder {
    font-size: 0.14rem;
    color: 999999 !important;
  } /* Firefox版本19+ */

  :-ms-input-placeholder {
    font-size: 0.14rem;
    color: 999999 !important;
  } /* IE浏览器 */

  input {
    position: relative;
    flex: 1;
    height: 0.32rem;
    // line-height: 0.32rem;
    font-size: 0.14rem;
    color: #999999;
     padding: 0.09rem 0.35rem 0.12rem 0.09rem;
  }
  .wap_search_icon {
    position: absolute;
    top: 0.1rem;
    right: 0.2rem;
    padding: 0.08rem 0.12rem;
    img {
      width: 0.2rem;
      height: 0.2rem;
    }
  }
}
.header-wap {
}
</style>
