import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/setRem'
import { naviGate } from './utils/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from './api/axios.config.js'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$siteId = process.env.VUE_APP_SITE_ID
Vue.prototype.api = api
Vue.prototype.naviGate = naviGate
ElementUI.Pagination.props.pagerCount = {
  validator(value) {
    return true
  }
}
const eventBus = new Vue()
new Vue({
  router,
  store,
  data() {
    return {
      eventBus
    }
  },
  render: (h) => h(App)
}).$mount('#app')
