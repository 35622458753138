<!--
 * @Module 主菜单
 -->
<template>
  <div class="menu-main">
    <div class="menu_box">
      <div :class="[selectIndex1 === index ? 'active' : '', 'menu_item']" :key="index" v-for="(item, index) in menuList" @click="onMenuItemClick(item, $event)" @mouseover="hoverElement(item)" @mouseout="outElement(item)">
        <div class="menu_name">{{ item.categoryTitle }}</div>

        <!-- <div v-if="index != menuList.length - 1 && selectIndex1 !== index" class="vertical_line"></div> -->

        <i v-show="item.isShowSubMenu && item.categoryTitle !== '首页' && item.categoryTitle != '文明校园' && item.categoryTitle != '智慧校园'" class="el-icon-caret-top upArrow"></i>

        <div v-show="item.isShowSubMenu && item.categoryTitle !== '首页' && item.categoryTitle != '文明校园' && item.categoryTitle != '智慧校园'" class="menu-sub_menu">
          <div :class="[
          selectIndex1 === index ? 'active' : '',
          'subMenu-item'
        ]">
            <div class="item_list">
              <!-- 有子菜单的一级菜单下菜单 -->
              <div v-if="item.child && item.child.length > 0">
                <div :class="[selectIndex1 === index && selectIndex2 === i ? 'active' : '', 'item-text']" :key="i" v-for="(obj, i) in item.child" @click="onMenuItemClick(obj, $event, item)">
                  {{ obj.categoryTitle }}
                </div>
              </div>

              <!-- 无子菜单的一级菜单下菜单 -->
              <div v-else-if="!item.child || item.child.length === 0">
                <div :class="[selectIndex1 === index && selectIndex2 === 0 ? 'active' : '', 'item-text']" @click="onMenuItemClick(item, $event)">
                  {{ item.categoryTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subMenu',
  data() {
    return {
      selectIndex1: 0,
      selectIndex2: -1
    }
  },
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    // 处理一级菜单选中状态
    this.handleSelect()

    this.$root.eventBus.$on('categoryId', (res) => {
      if (res) {
        // 梳理整合数据
        this.combination(res.categoryId)
      }
    })
  },
  methods: {
    /**
     * 处理一级菜单选中状态
     */
    handleSelect() {
      const {
        categoryId = null,
        id = null,
        contentId = null
      } = this.$route.query

      // 首页
      if (!categoryId && !id && !contentId) {
        this.selectIndex1 = 0
        this.selectIndex2 = -1
        return
      }

      if (this.menuList) {
        if (!contentId) {
          // 代表打开的是列表页
          if (categoryId) {
            // 代表子菜单的点击 (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击) 梳理整合数据
            this.combination(categoryId, id)
          } else {
            // 代表一级菜单的点击 (无子菜单的一级菜单) 梳理整合数据
            this.combination('', id)
          }
        }
      }
    },
    /**
     * 梳理整合数据
     * @param {categoryId} 父级id
     * @param {id} 本身id
     */
    combination(categoryId, id) {
      categoryId = parseInt(categoryId) ? parseInt(categoryId) : ''
      id = parseInt(id) ? parseInt(id) : ''

      // 获取父级菜单的详情数据
      let upLevelMenu = {}
      if (categoryId && categoryId !== '') {
        upLevelMenu = this.categoryList
          .filter((item) => item.id === categoryId)
          .pop()
      } else {
        upLevelMenu = this.categoryList.filter((item) => item.id === id).pop()
      }

      if (upLevelMenu) {
        if (!upLevelMenu.categoryId || upLevelMenu.categoryId === '') {
          // 代表打开的为第二级页面内容
          this.menuList.forEach((item, index) => {
            if (categoryId && categoryId !== '') {
              // 第二级子菜单点击打开内容时会传categoryId categoryId为第一级菜单的id (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击)
              if (item.id === categoryId) {
                this.selectIndex1 = index
                if (item.child) {
                  item.child.forEach((obj, i) => {
                    if (id && id !== '') {
                      if (obj.id === id) {
                        this.selectIndex2 = i
                      }
                    }
                  })
                } else {
                  this.selectIndex2 = 0
                }
              }
            } else {
              // 无子菜单的一级菜单点击打开内容时不传categoryId 以id进行数据匹配
              if (item.id === id) {
                this.selectIndex1 = index
                this.selectIndex2 = 0
              }
            }
          })
        } else {
          // 代表打开的为第三级页面内容 categoryId为第二级菜单的id
          this.menuList.forEach((item, index) => {
            if (item.child) {
              item.child.forEach((obj, i) => {
                if (obj.id === categoryId) {
                  this.selectIndex1 = index
                  this.selectIndex2 = i
                }
              })
            }
          })
        }
      }
    },
    /**
     * @description: 处理菜单鼠标划过状态
     * @param {object} item 菜单数据
     */
    hoverElement(item) {
      item.isShowSubMenu = true

      // 触发数据更新
      this.$forceUpdate()
    },
    /**
     * @description: 处理菜单鼠标离开状态
     * @param {object} item 菜单数据
     */
    outElement(item) {
      item.isShowSubMenu = false

      // 触发数据更新
      this.$forceUpdate()
    },
    /**
     * 菜单点击
     */
    onMenuItemClick(item, event, parentItem) {
      // 点击专题网站跳转至其下的第一个外链
      if (item.id == 49) {
        let firstUrl = item.child && item.child[0] && item.child[0].categoryDiyUrl
        window.open(firstUrl)
        return
      }
      if (event && event.stopPropagation) {
        event.stopPropagation()
      }

      if (parentItem) {
        parentItem.isShowSubMenu = false
      } else {
        item.isShowSubMenu = false
      }

      this.$emit('getmenu', item)
    }
  },
  watch: {
    $route(route) {
      const { categoryId = null, id = null, contentId = null } = route.query

      if (!categoryId && !id && !contentId) {
        this.selectIndex1 = 0
        this.selectIndex2 = -1
        return
      }

      // 处理一级菜单选中状态
      this.handleSelect()
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-main {
  width: 100%;
  height: 60px;
  background: #004797;
  /* position: fixed;
  top: 130px;
  left: 0;
  z-index: 99; */

  .menu_box {
    width: 1208px;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    // border-left: 1px solid #c6c6c6;
    // border-right: 1px solid #c6c6c6;

    .menu_item {
      flex: 1;
      flex-shrink: 0;
      height: 100%;
      cursor: pointer;
      font-size: 18px;
      color: #fff;
      background: transparent;
      position: relative;

      &.active {
        color: #fff !important;
        background: #409eff;
      }

      .menu_name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-weight: bold;
      }

      .vertical_line {
        width: 1px;
        height: 30px;
        background: #e2e2e2;
        position: absolute;
        top: 16px;
        right: 0;
      }

      .upArrow {
        position: absolute;
        top: 45px;
        left: 50%;
        margin-left: -12px;
        font-size: 24px;
        color: #fff;
      }

      .menu-sub_menu {
        display: flex;
        min-width: 100%;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px 0;
        z-index: 100;

        .subMenu-item {
          flex: 1;
          background: transparent;

          .subMenu-img {
            width: 100%;

            > img {
              width: 100%;
              height: auto;
              align-self: center;
            }
          }

          .item_list {
            height: 100%;

            .item-text {
              width: 100%;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 16px;
              color: #333333;
              padding: 0 10px;
              white-space: pre;
              cursor: pointer;
              background: transparent;

              &:hover {
                color: #409eff;
                background: #f1f1f1;
              }

              &.active {
                color: #409eff;
                background: #f1f1f1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
