<!--
 * @Module  底部
 -->

<template>
  <div>
    <!-- PC端展示内容 -->
    <div v-if="isPc" class="footer">
      <div class="footer_bg">
        <div class="logo_img">
          <img src="@/assets/images/common/logo.png" alt="" />
        </div>

        <div class="qr_code">
          <img src="@/assets/images/common/qr_code.png" alt="" />
        </div>

        <div class="dividing_line"></div>

        <div class="info_box">
          <div>地址：上海市崇明区长兴镇南圆沙路399号 邮编：201913</div>
          <div>江南造船集团职业技术学校 联系电话：021-33800628、33802640</div>
          <div>上海市江南长兴职业培训学校 联系电话：021-66991015、66991016</div>
        </div>

        <div class="dividing_line"></div>

        <div class="info_box">
          <div>沪公网安备：31023002000355号</div>
          <div style="cursor: pointer;" @click="toExternalLink">沪ICP备19016366号-1</div>
          <div>版权所有：江南造船集团职业技术学校</div>
        </div>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <div v-else class="footer-wap">
      <div class="footer_wap_msg">
        <div class="footer-wap_main">
          <div class="footer-wap_left">
            <div class="wap_text">
              <img src="@/assets/images/mobile/footer_1.png" alt="" />
              上海市崇明区长兴镇南圆沙路399号
            </div>
            <div class="wap_text" style="margin-bottom: 0px;">
              <img src="@/assets/images/mobile/footer_2.png" alt="" />
              江南造船集团职业技术学校
            </div>
            <div class="wap_text">
              <img src="@/assets/images/mobile/footer_3.png" alt="" />
              021-33800628、33802640
            </div>
            <div class="wap_text" style="margin-bottom: 0px;">
              <img src="@/assets/images/mobile/footer_2.png" alt="" />
              上海市江南长兴职业培训学校
            </div>
            <div class="wap_text">
              <img src="@/assets/images/mobile/footer_3.png" alt="" />
              021-66991015、66991016
            </div>
          </div>
          <div class="footer-wap_right">
            <img src="@/assets/images/common/logo.png" alt="" />
            <img
              src="@/assets/images/common/qr_code.png"
              style="margin-left: 0.12rem"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="footer_copyright">
        <div @click="toExternalLink" style="text-align: center;">
          沪ICP备19016366号-1<span style="margin: 0 0.08rem;">|</span>沪公网安备：31023002000355号
        </div>
        <div style="margin-top: 0.08rem; text-align: center;">版权所有：江南造船集团职业技术学校</div>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      linkValue: ''
    }
  },
  computed: {
    ...mapState(['isPc'])
  },
  props: { siteInfo: Object, links: Array },
  methods: {
    linkChange(url) {
      if (!url) return
      window.open(url)
    },

    /**
     * 跳转至 ICP/IP地址/域名信息备案管理系统
     */
    toExternalLink() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;

  .footer_bg {
    height: 227px;
    background: url('~@/assets/images/pc/footer_bg.png') no-repeat;
    background-size: auto 100%;
    background-color: #0d55a8;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo_img {
      width: 120px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .qr_code {
      width: 106px;
      margin-left: 30px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .dividing_line {
      width: 1px;
      height: 104px;
      background: rgba(255, 255, 255, 0.28);
      margin: 0 30px;
    }

    .info_box {
      height: 104px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;
      color: #fff;
      line-height: 32px;
    }
  }
}

.footer-wap {
  .footer_wap_msg {
    width: 100%;
    // height: 1.76rem;
    background: url('~@/assets/images/mobile/bg_footer.png') no-repeat;
    background-size: 100% 100%;
    background-color: #0d55a8;

    .footer-wap_main {
      padding: 0.2rem;
      display: flex;
      align-items: center;
      position: relative;
      .footer-wap_left {
        .wap_text {
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          color: #fff;
          margin-bottom: 0.08rem;
          font-family: PingFangSC-Regular, PingFang SC;
          img {
            width: 0.24rem;
            height: 0.24rem;
            margin-right: 0.04rem;
          }
        }
        .wap_text:last-child {
          margin-bottom: 0;
        }
      }
      .footer-wap_right {
        position: absolute;
        right: 0.2rem;
        display: flex;
        align-items: center;
        img {
          width: 0.54rem;
          height: 0.54rem;
        }
      }
    }
  }
  .footer_copyright {
    padding: 0.2rem 0;
    // display: flex;
    // justify-content: center;
    background: #3e4856;
    font-size: 0.12rem;
    color: #B2B2B2;
  }
}
</style>
