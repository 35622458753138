<!--
 * @Module  首页
 -->

<template>
  <!-- PC端展示内容 -->
  <div v-if="isPc" class="main-content">
    <div class="content_subject">
      <div class="subject_box">
        <!-- 焦点图 -->
        <!-- style="margin-left: -4px;"  -->
        <Carousel :carouselList="carouselList" />
        <div class="line_box multi_box" style="margin-top: 40px">
          <!-- 通知公告 -->
          <div class="flex_1">
            <div class="column_header">
              <img src="@/assets/images/common/column_icon6.png" alt="" />

              <div>通知公告</div>

              <div class="more_btn" @click="handleMore(noticeAnnounceId)">
                MORE
              </div>
            </div>

            <div class="column_box h_272">
              <div class="item_box">
                <div
                  v-for="(item, index) in noticeAnnounce"
                  :key="index"
                  class="jointly_item"
                  @click="handleRow(item)"
                >
                  <div class="item_title">
                    <!-- <div class="title_dot"></div> -->
                    <div class="title_monthDay">
                      {{ getDateTime(item.contentTime, 'm-d') }}
                    </div>
                    <div class="title_text" style="margin-right: 64px">
                      {{ item.contentTitle }}
                    </div>
                    <!-- <div class="title_time">{{ getDateTime(item.contentTime, 'm-d') }}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 校园动态 -->
          <div class="flex_1 ml_40">
            <div class="column_header">
              <img src="@/assets/images/common/column_icon2.png" alt="" />
              <div>校园动态</div>
              <div class="more_btn" @click="handleMore(campusDynamicId)">
                MORE
              </div>
            </div>
            <div class="column_box h_272">
              <div class="item_box">
                <div
                  v-for="(item, index) in campusDynamics"
                  :key="index"
                  class="jointly_item"
                  @click="handleRow(item)"
                >
                  <div class="item_title">
                    <div class="title_dot"></div>
                    <div class="title_text">{{ item.contentTitle }}</div>
                    <div class="title_time">
                      {{ getDateTime(item.contentTime) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line_box" style="padding-top: 40px">
          <!-- 学校要闻 -->
          <div class="column_header">
            <img src="@/assets/images/common/column_icon1.png" alt="" />

            <div>学校要闻</div>

            <div class="more_btn" @click="handleMore(focusNewsId)">MORE</div>
          </div>

          <div class="column_box h_385">
            <div class="item_box mr_20">
              <el-carousel
                loop
                :autoplay="false"
                height="385px"
                style="cursor: pointer"
              >
                <el-carousel-item
                  v-for="(item, index) in focusNews"
                  :key="index"
                  @click.native="handleRow(item)"
                >
                  <img
                    :src="handleUrl(item.contentImg)"
                    :alt="item.contentTitle"
                    class="home_show_img"
                  />

                  <div class="title_box" style="padding: 0 165px 0 20px">
                    {{ item.contentTitle }}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

            <div class="item_box focus_news ml_20">
              <div
                v-for="(item, index) in focusNews"
                :key="index"
                @click="handleRow(item)"
              >
                <div
                  v-if="index == 0"
                  class="focus_news_item flex-col"
                  style="padding: 0 0 18px"
                >
                  <div class="item_title">
                    <div class="title_dot"></div>
                    <img
                      v-if="item.contentSort < 0"
                      class="icon_hot"
                      src="@/assets/images/common/icon_hot.png"
                      alt=""
                    />
                    <div class="focus_news_text">{{ item.contentTitle }}</div>
                    <!-- <div v-else class="focus_news_otherText">{{ item.contentTitle }}</div> -->
                    <div class="title_time">
                      {{ getDateTime(item.contentTime) }}
                    </div>
                  </div>

                  <div class="description">{{ item.contentDescription }}</div>
                </div>
                <div v-else class="focus_news_item">
                  <div class="item_title">
                    <div class="title_dot"></div>
                    <img
                      v-if="item.contentSort < 0"
                      class="icon_hot"
                      src="@/assets/images/common/icon_hot.png"
                      alt=""
                    />
                    <div class="focus_news_otherText">
                      {{ item.contentTitle }}
                    </div>
                    <div class="title_time">
                      {{ getDateTime(item.contentTime) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新闻中心 -->
        <!-- <div class="line_box">
            <div class="column_header">
              <img src="@/assets/images/common/column_icon2.png" alt="">

              <div>{{ newsCenter ? newsCenter.categoryTitle : ''}}</div>

              <div class="tab_box">
                <template v-if="newsCenter">
                  <div v-for="(item, index) in newsCenter.child || []" :key="index" :class="[{ active_tab: selectNewsCenter == index }, 'tab_column']" @click="selectTab(item, index)">{{ item.categoryTitle || '' }}</div>
                </template>
              </div>

              <div class="more_btn" @click="handleCategoryMore(newsCenter)">MORE</div>
            </div>

            <div class="column_box h_272">
              <div class="item_box center_news">
                <div v-for="(item, index) in newsCenterNews || []" :key="index" class="news_item" @click="handleRow(item)">
                  <div class="item_title">
                    <div class="title_dot"></div>
                    <div class="title_text">{{ item.contentTitle }}</div>
                    <div class="title_time">{{ getDateTime(item.contentTime) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        <div class="line_box multi_box">
          <!-- 专业设置 -->
          <div class="flex_1">
            <div class="column_header">
              <img src="@/assets/images/common/column_icon3.png" alt="" />

              <div>
                {{ specialtySetting ? specialtySetting.categoryTitle : '' }}
              </div>

              <div
                class="more_btn"
                @click="handleCategoryMore(specialtySetting)"
              >
                MORE
              </div>
              <!--  -->
            </div>

            <div class="column_box h_272">
              <div
                class="item_box"
                style="flex: 1; !important; margin-right: 20px;"
              >
                <template v-if="specialtySetting">
                  <div
                    v-for="(item, index) in specialtySetting.child || []"
                    :key="index"
                    :class="[
                      { specialty_active: selectSpecialty == index },
                      'specialty_item'
                    ]"
                    @mouseover="hoverSpecialty(index)"
                    @click="handleCategoryMore(item)"
                  >
                    <img
                      v-if="selectSpecialty == index"
                      src="@/assets/images/pc/icon_column_list_select.png"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/pc/icon_column_list.png"
                      alt=""
                    />

                    <div>{{ item.categoryTitle || '' }}</div>
                  </div>

                  <div
                    class="specialty_item specialty_more"
                    @click="handleCategoryMore(specialtySetting)"
                  >
                    <div class="more_icon"></div>

                    <div>
                      <span style="flex: none; margin-right: 12px">更多</span>
                      <i class="el-icon-d-arrow-right"></i>
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="
                  specialtySetting &&
                  specialtySetting.child &&
                  specialtySetting.child[selectSpecialty]
                "
                @click="
                  handleCategoryMore(
                    specialtySetting.child[selectSpecialty],
                    $event
                  )
                "
                class="img_box"
                style="cursor: pointer; width: 400px; !important;"
              >
                <img
                  :src="
                    handleUrl(
                      specialtySetting.child[selectSpecialty].categoryImg
                    )
                  "
                  class="box_img"
                  alt=""
                />

                <div style="padding: 0 20px" class="title_box">
                  {{
                    specialtySetting.child[selectSpecialty].categoryTitle || ''
                  }}
                </div>
              </div>
            </div>
          </div>

          <!-- 德育教学 -->
          <div class="ml_40" style="width: 400px">
            <div class="column_header">
              <img src="@/assets/images/common/column_icon4.png" alt="" />

              <div>德育教学</div>

              <div class="more_btn" @click="handleMore(jointlySponsorId)">
                MORE
              </div>
            </div>

            <div class="column_box h_272">
              <div class="item_box">
                <div
                  v-for="(item, index) in jointlySponsor"
                  :key="index"
                  class="jointly_item"
                  @click="handleRow(item)"
                >
                  <div class="item_title">
                    <div class="title_dot"></div>
                    <div class="title_text">{{ item.contentTitle }}</div>
                    <div class="title_time">
                      {{ getDateTime(item.contentTime) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 快速通道 -->
    <div class="express_lane">
      <div class="express_lane_title">
        <img src="@/assets/images/common/column_icon5.png" alt="" />
        <div>快速通道</div>
      </div>

      <div class="express_lane_list">
        <template v-if="expressLane">
          <div
            v-for="(item, index) in expressLane"
            :key="index"
            class="express_lane_item"
            @click="handleRow(item)"
          >
            <img :src="handleUrl(item.contentImg)" alt="" />

            <div>{{ item.contentTitle || '' }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- wap端展示内容 -->
  <div class="main-content-wap" v-else>
    <div class="content_subject">
      <div class="subject_box">
        <!-- 焦点图 -->
        <Carousel :carouselList="carouselList" />
        <!-- 通知公告 -->
        <div class="line_box multi_box" style="padding-top: 0.23rem">
          <div>
            <div class="column_header">
              <img src="@/assets/images/common/column_icon6.png" alt="" />

              <div>通知公告</div>

              <div class="more_btn" @click="handleMore(noticeAnnounceId)">
                更多
              </div>
            </div>

            <div class="column_box">
              <div class="item_box">
                <div
                  v-for="(item, index) in noticeAnnounce.slice(0, 4)"
                  :key="index"
                  class="jointly_item"
                  style="margin-top: 0.2rem"
                  @click="handleRow(item)"
                >
                  <div class="item_title">
                    <!-- <div class="title_dot"></div> -->
                    <div class="title_date">
                      <div class="title_date_day">
                        {{ getDateTime(item.contentTime, 'day') }}
                      </div>
                      <div class="title_date_yearMonth">
                        {{ getDateTime(item.contentTime, 'y-m') }}
                      </div>
                    </div>
                    <div class="title_text">
                      <span  style="font-weight: bold;">{{ item.contentTitle }}</span>
                      <div v-if="item.contentDescription">
                        {{ item.contentDescription }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 学校要闻 -->
        <div class="line_box multi_box" style="padding-bottom: 0.4rem">
          <div class="column_header">
            <img src="@/assets/images/common/column_icon1.png" alt="" />

            <div>学校要闻</div>

            <div class="more_btn" @click="handleMore(focusNewsId)">更多</div>
          </div>

          <div class="column_box">
            <div class="item_box">
              <div class="focus_news">
                <div
                  v-for="(item, index) in focusNews.slice(0, 4)"
                  :key="index"
                  @click="handleRow(item)"
                >
                  <div v-if="index == 0" class="focus_news_first">
                    <img
                      :src="handleUrl(item.contentImg)"
                      alt=""
                      class="news_first_img"
                    />
                    <div class="news_first_title">
                      <div class="focus_news_text">
                        <img
                          v-if="item.contentSort < 0"
                          class="icon_hot"
                          src="@/assets/images/common/icon_hot.png"
                          alt=""
                        />
                        {{ item.contentTitle }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="focus_news_item">
                    <div class="item_title">
                      <div class="title_dot"></div>
                      <img
                        v-if="item.contentSort < 0"
                        class="icon_hot"
                        src="@/assets/images/common/icon_hot.png"
                        alt=""
                      />
                      <div class="focus_news_otherText">
                        {{ item.contentTitle }}
                      </div>
                      <div class="title_time">
                        {{ getDateTime(item.contentTime, 'm-d') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 校园动态 -->
        <div
          class="line_box multi_box"
          style="background: #eef7ff; padding-bottom: 0.4rem; padding-top: 0.2rem;"
        >
          <div class="column_header">
            <img src="@/assets/images/common/column_icon2.png" alt="" />
            <div>校园动态</div>
            <div class="more_btn" @click="handleMore(campusDynamicId)">
              更多
            </div>
          </div>
          <div
            class="column_box"
            style="
              background: #fff;
              padding: 0.2rem 0.16rem 0.22rem;
              margin-top: 0.2rem;
            "
          >
            <div class="item_box">
              <div
                v-for="(item, index) in campusDynamics.slice(0, 4)"
                :key="index"
                class="jointly_item"
                :style="{marginBottom: index == 3 ? '0' : '0.36rem'}"
                @click="handleRow(item)"
              >
                <div class="jointly_item_time">
                  <img src="@/assets/images/mobile/icon_time.png" alt="" />
                  {{ getDateTime(item.contentTime) }}
                </div>
                <div class="jointly_item_title">{{ item.contentTitle }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 专业设置 -->
        <div class="line_box multi_box">
          <div class="column_header">
            <img src="@/assets/images/common/column_icon3.png" alt="" />

            <div>
              {{ specialtySetting ? specialtySetting.categoryTitle : '' }}
            </div>

            <!-- <div class="more_btn" @click="handleCategoryMore(specialtySetting)">
              更多
            </div> -->
          </div>

          <div class="column_box" style="display: block">
            <div v-for="(item, index) in specialtySetting.child" :key="index">
              <div
                @click="handleCategoryMore(item, $event)"
                class="img_box"
                style="margin-top: 0.2rem"
              >
                <div class="focus_news_item">
                  <div class="item_title">
                    <img src="@/assets/images/mobile/icon_course.png" alt="" class="title_img">
                    <div class="title_text">{{ item.categoryTitle }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 德育教学 -->
        <div class="line_box multi_box" style="padding-bottom: 0.4rem">
          <div class="column_header">
            <img src="@/assets/images/common/column_icon4.png" alt="" />

            <div>德育教学</div>

            <div class="more_btn" @click="handleMore(jointlySponsorId)">
              更多
            </div>
          </div>

          <div class="column_box">
            <div class="item_box">
              <div class="focus_news">
                <div
                  v-for="(item, index) in jointlySponsor"
                  :key="index"
                  @click="handleRow(item)"
                >
                  <div class="focus_news_item">
                    <div class="item_title">
                      <div class="title_dot"></div>
                      <div class="title_text">{{ item.contentTitle }}</div>
                      <div class="title_time">
                        {{ getDateTime(item.contentTime, 'm-d') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 快速通道 -->
    <div class="express_lane">
      <div class="express_lane_title">
        <img src="@/assets/images/common/column_icon5.png" alt="" />
        <div>快速通道</div>
      </div>

      <div style="padding: 0 0.2rem 0.2rem">
        <div class="express_lane_list">
          <template v-if="expressLane">
            <div
              v-for="(item, index) in expressLane"
              :key="index"
              class="express_lane_item"
              @click="handleRow(item)"
            >
              <img :src="handleUrl(item.contentImg)" alt="" />

              <span>{{ item.contentTitle || '' }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import Carousel from '@/components/carousel/banner'
import { mapState, mapGetters } from 'vuex'
import { dateObj, convertImgUrl } from '@/utils/index'
export default {
  name: 'MachineIndex',
  components: { Carousel },
  data() {
    return {
      carouselId: 42,
      carouselList: [], // 焦点图

      noticeAnnounceId: 52,
      noticeAnnounce: [], // 通知公告

      campusDynamicId: 53,
      campusDynamics: [], // 校园动态

      focusNewsId: 54,
      focusNews: [], // 江南要闻

      newsCenterId: 51,
      newsCenter: {}, // 新闻中心栏目数据
      selectNewsCenter: 0,
      newsCenterNews: [], // 新闻中心子级选中tab数据

      specialtySettingId: 45,
      // specialtySetting: {}, // 专业设置栏目数据
      selectSpecialty: 0, // 专业设置选中子级栏目

      jointlySponsorId: 58,
      jointlySponsor: [], // 德育工作

      expressLaneId: 73,
      expressLane: [] // 快速通道
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getListData('carouselList', this.carouselId, 5) // 焦点图

      this.getListData('noticeAnnounce', this.noticeAnnounceId, 7) // 通知公告

      this.getListData('campusDynamics', this.campusDynamicId, 7) // 校园动态

      this.getListData('focusNews', this.focusNewsId, 6, 'contentSort') // 江南要闻

      this.getListData('jointlySponsor', this.jointlySponsorId, 6) // 德育教学

      this.getListData('expressLane', this.expressLaneId, 15, 'createTime') // 快速通道

      if (this.allMenuList) {
        // 获取页面展示的栏目
        this.getShowColumn(this.allMenuList || [])
      } else {
        // 打开的详情页时获取的栏目id 进行数据整理
        this.$root.eventBus.$on('allMenu', (res) => {
          if (res) {
            // 获取页面展示的栏目
            this.getShowColumn(res.allMenuList || [])
          }
        })
      }
    },
    /**
     * @description: 获取页面展示的栏目
     * @params {array} 全部菜单栏目数据
     */
    getShowColumn(allMenuList) {
      // 递归获取id相应的菜单-新闻中心数据
      // let newsCenter = this.getIdColumn(this.newsCenterId, allMenuList)
      // this.newsCenter = newsCenter
      // if (
      //   this.newsCenter &&
      //   this.newsCenter.child &&
      //   this.newsCenter.child[this.selectNewsCenter]
      // ) {
      //   // 获取栏目下文章
      //   this.getListData(
      //     'newsCenterNews',
      //     this.newsCenter.child[this.selectNewsCenter].id,
      //     12
      //   )
      // }
      // 递归获取id相应的菜单-专业设置数据
      // let specialtySetting = this.getIdColumn(
      //   this.specialtySettingId,
      //   allMenuList
      // )
      // if (specialtySetting && specialtySetting.child) {
      //   specialtySetting.child = specialtySetting.child.slice(0, 4)
      // }
      // this.specialtySetting = specialtySetting
      // console.log(this.specialtySetting)
    },
    /**
     * @description: 递归获取id相应的菜单
     * @param {number} id
     * @param {array} list
     */
    getIdColumn(id, list = []) {
      let columnData = []
      list.forEach((item) => {
        if (id == item.id) {
          columnData = item
        } else {
          if (item.child && item.child.length && !columnData) {
            columnData = this.getIdColumn(id, item.child)
          }
        }
      })

      return columnData
    },
    /**
     * @description: 栏目选中切换
     * @param {object} item 栏目数据
     * @param {number} index 栏目索引
     */
    selectTab(item, index) {
      this.selectNewsCenter = index

      // 获取栏目下文章
      this.getListData('newsCenterNews', item.id, 12)
    },
    /**
     * @description: 获取栏目下的信息列表
     * @param {number} categoryId 栏目ID
     * @param {number} pageSize 显示列表个数
     * @param {number} sortName 排序字段
     */
    async getListData(target, categoryId, pageSize = '', sortName) {
      let orderProp = [{ column: 'contentTime', asc: false }]
      if (sortName == 'createTime') orderProp = [{ column: 'contentTime', asc: true }]
      try {
        if (!categoryId) {
          console.log('list id is null')
          return
        }
        const { errCode, data } = await this.api.post(
          '/webclient/getContentList',
          {
            categoryId,
            pageNum: 1,
            pageSize: 20,
            contentDisplay: false,
            orders: orderProp
          }
        )
        if (!errCode) {
          const { records } = data
          this.$data[target] = (records || [])
            .filter((item) => item.contentDisplay !== 'hidden')
            .slice(0, pageSize)
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * @description: 专业设置hover
     * @param {number} index 专业设置子栏目索引
     */
    hoverSpecialty(index) {
      this.selectSpecialty = index
    },

    /**
     * @description: 栏目查看
     * @param {object} 栏目数据
     */
    handleList(item) {
      this.$emit('clickMenu', item)
    },

    /**
     * @description: 更多
     * @param {number} id (categoryId或id)
     */
    handleMore(id) {
      const { categoryId } = this.categoryList
        .filter((item) => item.id === id)
        .pop()
      // 二级栏目
      if (categoryId) {
        this.naviGate('list', {
          t: new Date().getTime(),
          categoryId,
          id
        })
      } else {
        // 一级栏目，id即categoryId
        this.naviGate('list', {
          t: new Date().getTime(),
          id
        })
      }
    },
    /**
     * @description: 当前行的跳转逻辑
     * @param {object} row 行数据
     */
    handleRow(row) {
      console.log(row)
      const { id } = row
      if (row.contentUrl) {
        // 外链
        window.open(row.contentUrl)
      } else {
        // 内链
        this.naviGate('detail', {
          t: new Date().getTime(),
          contentId: id
        })
      }
    },
    /**
     * @description: 多级栏目查看更多
     * @param {object} 栏目数据
     */
    handleCategoryMore(data) {
      if (data) {
        if (data.child && data.child[0]) {
          this.handleMore(data.child[0].id)
        } else {
          this.handleMore(data.id)
        }
      }
    },

    // onMenuItemClick(item, event) {
    //   if (event && event.stopPropagation) {
    //     event.stopPropagation()
    //   }
    //   console.log(111, item);
    //   this.$emit('getmenu', item)
    // },
    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },
    /**
     * @description: 时间格式转换 YYYY.MM.DD
     * @param {number} time 时间戳
     * @param {string} type monthDay月-日 默认年-月-日
     */
    getDateTime(time, type) {
      let date = dateObj(time)
      if (type == 'day') {
        return date.day
      } else if (type == 'y-m') {
        return date.year + '-' + date.month
      } else if (type == 'm-d') {
        return date.month + '-' + date.day
      } else {
        return date.year + '-' + date.month + '-' + date.day
      }
    },
    loadStyle() {
      if (this.isPc) {
        require('@/assets/module/pc/index.scss')
      } else {
        require('@/assets/module/wap/index.scss')
      }
    }
  },
  computed: {
    ...mapState(['allMenuList', 'categoryList', 'isPc']),
    ...mapGetters(['menuList']),

    specialtySetting: {
      get() {
        // 递归获取id相应的菜单-专业设置数据
        let specialtySetting = this.getIdColumn(
          this.specialtySettingId,
          this.allMenuList
        )
        if (specialtySetting && specialtySetting.child) {
          specialtySetting.child = this.isPc ? specialtySetting.child.slice(0, 4) : specialtySetting.child.slice(0, 11)
        }
        // this.specialtySetting = specialtySetting
        // console.log(this.specialtySetting);
        return specialtySetting
      }
    }
  },
  watch: {
    isPc: {
      handler: 'loadStyle',
      immediate: true
    }
  },
  filters: {}
}
</script>

<style lang="scss" scoped>
/* 首页 pc端 */
.main-content {
  ::deep {
    .el-carousel {
      .el-carousel__indicators--horizontal {
        bottom: 7px;
        right: -33px;
        left: auto;
      }

      .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
      }

      .el-carousel__indicator.is-active button {
        background: #be1418;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.home_show_img {
  width: 100%;
  object-fit: contain;
}
</style>
