<template>
  <!-- :class="[isPc ? 'min_w_pc' : '', 'container']" -->
  <div v-if="isPc" class="container">
    <!-- logo -->
    <Header />

    <!-- 菜单 -->
    <div class="menu" v-if="isPc">
      <Menu
        v-if="categoryList.length > 0"
        :categoryList="categoryList"
        :menuList="menuList"
        @getmenu="handleMenu"
      />
    </div>

    <!-- 渲染区域 -->
    <div class="main">
      <router-view />
    </div>

    <!-- 版权 -->
    <Footer v-if="siteInfo" :siteInfo="siteInfo" :links="links" />

    <template v-if="isPc">
      <template v-for="(item, index) in siteAdvert">
        <!-- 浮窗广告 -->
        <FloatAdv
          :key="index"
          v-if="item.advertStatus == 1 && item.scriptType == 1 && item.show"
          :json="item"
          @closeView="closeView"
        ></FloatAdv>

        <!-- 蒙层广告 -->
        <MaskAdv
          :key="index"
          v-if="item.advertStatus == 1 && item.scriptType == 2 && item.show"
          :json="item"
          @closeView="closeView"
        ></MaskAdv>

        <!-- 静态广告 -->
        <StaticAdv
          :key="index"
          v-if="showStaticView"
          :json="staticJson"
          @closeView="closeStaticView"
        ></StaticAdv>
      </template>
    </template>
  </div>
  <div v-else class="container_wap">
    <!-- logo -->
    <Header />

    <div class="scroll_area">
      <!-- 渲染区域 -->
      <div class="main">
        <router-view @clickMenu="handleMenu" />
      </div>
      <!-- wap端侧边栏 -->
      <Sidebar
        v-if="categoryList.length > 0"
        :categoryList="categoryList"
        :menuList="menuList"
        @getmenu="handleMenu"
      />
      <!-- 版权 -->
      <Footer v-if="siteInfo" :siteInfo="siteInfo" :links="links" />
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/header'
import Menu from '@/components/menu/index'
import Sidebar from '@/components/sidebar/index'
import Footer from '@/components/footer/footer'
import FloatAdv from '@/components/advertisement/float-adv'
import MaskAdv from '@/components/advertisement/mask-adv'
import StaticAdv from '@/components/advertisement/static-adv'

import { mapState } from 'vuex'
import { addREvent } from '@/utils/setRem'
import { listToTree, getDeviceType } from '@/utils/index'
export default {
  name: 'MachineCommon',
  data() {
    return {
      defaultProps: {
        children: 'child',
        label: 'categoryTitle',
        index: 'id'
      },
      categoryList: [],
      menuList: [],
      detail: [],
      carouselList: [],
      links: [],
      // 浮窗广告
      showFloatView: false,
      floatJson: {
        advertContent: '', // 广告图片
        linkUrl: '', // 广告跳转地址
        left: 1400,
        top: 300,
        directionX: '',
        directionY: '',
        distance: null,
        interval: null,
        target: null,
        zIndex: null,
        width: null,
        height: null,
        noHoverPause: null, // 鼠标经过时不停止，为true时不停止
        cursor: null
      },
      // 蒙层广告
      showMaskView: false,
      maskJson: {
        width: '',
        height: ''
      },
      // 静态广告
      showStaticView: false,
      staticJson: {
        width: '130px',
        height: '100px',
        position: 'right'
      },
      siteAdvert: [] // 站点广告数据
    }
  },
  beforeCreate() {
    // 页面刷新,获取最新栏目列表
    this.$store.commit('setCategoryList', null)
  },
  created() {
    this.init()
    addREvent(() => {
      this.setFacility()
    })
  },
  methods: {
    closeView({ id }) {
      this.siteAdvert.forEach((item) => {
        if (item.id == id) item.show = false
      })
    },

    init() {
      this.getListData('links', 67, 4) // 页尾图
      this.getSiteInfor()
      this.getSiteCategory()

      // 设置设备类型
      this.setFacility()
    },
    /**
     * 获取站点数据
     */
    async getSiteInfor() {
      try {
        const { errCode, data } = await this.api.post(
          '/webclient/getSiteInfo',
          {
            siteId: this.$siteId
          }
        )
        if (!errCode) {
          this.getSiteAdvert()
          this.$store.commit('setSiteInfo', data)
        }
      } catch (error) {
        console.log('getSiteInfo：异常')
      }
    },

    /**
     * @description: 获取站点所有浮窗广告信息
     */
    async getSiteAdvert() {
      try {
        const { errCode, data } = await this.api.post(
          '/webclient/getSiteAdvert',
          {
            siteId: this.$siteId
          }
        )
        if (!errCode) {
          data.forEach((item) => {
            item.show = true
            item.scriptConfig = JSON.parse(item.scriptConfig)
            if (Object.keys(item.scriptConfig).length != 0) {
              for (let j in item.scriptConfig) {
                item[j] = item.scriptConfig[j]
                // item.push(item.scriptConfig[j])
              }
            }
          })
          this.siteAdvert = data || []
        }
      } catch (error) {
        console.log('getSiteAdvert：异常', error)
      }
    },

    async getSiteCategory() {
      try {
        const { errCode, data } = await this.api.post(
          '/webclient/getSiteCategory',
          {
            siteId: this.$siteId
          }
        )
        if (!errCode) {
          // 排序树结构数据
          data.sort(function (a, b) {
            return a.categorySort - b.categorySort
          })

          // 存储全部菜单数据
          let allMenuList = listToTree(data || [], 'id', 'categoryId', 'child')
          this.$store.commit('setAllMenuList', allMenuList)

          // 通知并给出完整的栏目数据
          this.$root.eventBus.$emit('allMenu', {
            allMenuList: allMenuList || []
          })

          // 菜单栏目（isMenu为 1显示）
          const filterData = (data || []).filter((item) => item.isMenu)
          this.categoryList = filterData
          this.menuList = listToTree(filterData, 'id', 'categoryId', 'child')
          this.$store.commit('setCategoryList', filterData)
        }
      } catch (error) {
        console.log('getSiteCategory：异常')
      }
    },
    async getListData(target, categoryId = '', pageSize = '') {
      try {
        if (!categoryId) {
          console.log('list id is null')
          return
        }
        const { errCode, data } = await this.api.post(
          '/webclient/getContentList',
          {
            categoryId,
            pageNum: 1,
            pageSize,
            contentDisplay: false,
            orders: [
              // { column: 'contentSort', asc: true },
              { column: 'contentTime', asc: false },
              { column: 'id', asc: false }
            ]
          }
        )
        if (!errCode) {
          const { records } = data
          this.$data[target] = records || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 获取当前菜单信息
     * @param {object} item 当前菜单
     * @param {number} isLeaf 0:列表页 1:详情页
     */
    async handleMenu(item) {

      const { categoryId = '', id, isLeaf, categoryDiyUrl } = item

      if (categoryDiyUrl && categoryDiyUrl !== '') {
        // 外链
        window.open(categoryDiyUrl)
        return
      }

      if (!item.id || item.categoryTitle === '首页') {
        this.naviGate('index', { t: new Date().getTime() }) // 首页id:0(特殊)
      } else {
        // 判断是否为一级栏目(为空或不存在为第一级栏目)
        if (categoryId) {
          if (isLeaf) {
            // 根据id请求getContentList接口，然后在请求getContent，渲染详情页
            await this.getListData('detail', id)

            // 前往下级页面
            this.toSubordinatePage(this.detail, categoryId, id)
          } else {
            this.naviGate('list', { t: new Date().getTime(), categoryId, id })
          }
        } else {

          // 一级栏目
          if (isLeaf) {
            // 根据id请求getContentList接口，然后在请求getContent，渲染详情页
            await this.getListData('detail', id)

            // 前往下级页面
            this.toSubordinatePage(this.detail, categoryId, id)
          } else {
            // 二级栏目，若存在child，取第一个对象categoryId，id
            if (item.child && item.child.length) {
              const { categoryId, id, isLeaf, categoryDiyUrl } = item.child[0]
              /** 点击专题网站直接跳转下级菜单第一项外链 */
              if (categoryId == 49) {
                // 外链
                window.open(categoryDiyUrl)
              }
              if (isLeaf) {
                // 详情页
                await this.getListData('detail', id)

                // 前往下级页面
                this.toSubordinatePage(this.detail, categoryId, id)
              } else {
                // 列表页
                this.naviGate('list', {
                  t: new Date().getTime(),
                  categoryId,
                  id
                })
              }
            } else {
              this.naviGate('list', {
                t: new Date().getTime(),
                id
              })
            }
          }
        }
      }
    },
    /**
     * @description: 前往下级页面
     * @param {array} detail 详情数据
     * @param {number} categoryId 菜单父级id
     * @param {array} id 菜单id
     */
    toSubordinatePage(detail, categoryId, id) {
      if (detail && detail.length > 0) {
        const [{ id: contentId, contentDisplay }] = detail
        if (contentDisplay && contentDisplay !== 'hidden') {
          this.naviGate('detail', {
            t: new Date().getTime(),
            contentId
          })
        } else {
          this.naviGate('list', {
            t: new Date().getTime(),
            categoryId,
            id
          })
        }
      } else {
        this.naviGate('list', {
          t: new Date().getTime(),
          categoryId,
          id
        })
      }
    },
    /**
     * 设置设备类型
     */
    setFacility() {
      const facility = getDeviceType()
      if (this.isPc === !facility) return
      // 是否为PC端，值存入vuex。true:pc；fasle:移动端
      this.$store.commit('setFacility', !facility)
    }
  },
  computed: {
    ...mapState(['siteInfo', 'isPc'])
  },
  components: { Header, Footer, Menu, Sidebar, FloatAdv, MaskAdv, StaticAdv }
}
</script>
<style lang="scss" scoped>
.container {
  // width: 100%;
  min-width: 1280px;
  height: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 820px) {
  }
  // &,
  // .min_w_pc {
  //   @media screen and (max-width: 820px) {
  //     overflow-x: hidden;
  //   }
  // }

  .menu {
    width: 100%;
    height: 60px;
    background: #fff;
    flex-shrink: 0;
  }

  .main {
    width: 100%;
    flex: 1 1 auto;

    @media screen and (max-width: 820px) {
      flex: 1;
    }
  }
}
.container_wap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  .scroll_area {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
