<template>
  <div class="floatLayer" :style="{height: json.height ? (Number(json.height) + 22) + 'px' : '154px'}">
    <div style="position: relative;" @click="skipExternalLinks(json.linkUrl)" :style="{display: json.advertType == 1 ? '' : 'flex'}">
      <img v-if="json.advertType == 1"  :src="handleUrl(json.advertContent)" alt="" style="margin-top: 22px;"  :style="[{width: json.width ? json.width + 'px' : '160px'}, {height: json.height ? json.height + 'px' : '132px'}]">
      <div v-else class="htmlCss" style="margin-top: 22px;" :style="[{width: json.width ? json.width + 'px' : '160px'}, {height: json.height ? json.height + 'px' : '132px'}]">
        <div v-html="json.advertContent" :style="{height: json.height ? json.height + 'px'  : '154px'}"></div>
      </div>
      <img class="close-btn" src="@/assets/images/common/icon_close.png" alt="" style="width: 32px; height: 32px;" href="javascript:;" @click.stop="closeView">
    </div>
  </div>
</template>
<script>
import { convertImgUrl } from '@/utils/index'
export default {
  name: 'FloatAdv',
  data() {
    return {
      object: [],
      left: 300,
      top: 300,
      interval: 30,
      distance: 2,
      directionX: 1,
      directionY: 1,
      zIndex: 100,
      target: '_blank',
      cursor: 'pointer',
      i: 0
    }
  },
  props: ['json'],
  mounted() {
    // 广告持续时间
    if (this.json.displayTime) {
      this.countDown(this.json.displayTime)
    }
    this.init(this.json)
  },
  methods: {
    closeView() {
      this.$emit('closeView', { id: this.json.id })
    },

    countDown(time) {
      // 开始倒计时
      var time_lang = time
      var timeOver = setInterval(() => {
        --time_lang
        if (time_lang > 0) {
        } else {
          // 倒计时小于0
          time_lang = time
          clearInterval(timeOver)
          this.closeView()
        }
      }, 1000)
    },

    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },

    /**
     * @description: 跳转外部链接
     * @param {*} url 外部链接地址
     */
    skipExternalLinks(url) {
      console.log(url, '===========22222')
      window.open(url, '_blank')
    },

    init(json) {
      var obj = document.querySelector('.floatLayer')
      //自定义
      obj.left = parseInt(json.left || this.left)
      obj.top = parseInt(json.top || this.top)
      obj.interval = json.interval || this.interval
      obj.distance = json.distance || this.distance
      obj.directionX = json.directionX || this.directionX
      obj.directionY = json.directionY || this.directionY
      obj.index = this.object.length
      obj.url = json.url
      obj.target = json.target || this.target
      obj.t = null
      //事件
      if (obj.url != undefined) {
        obj.onclick = function () {
          switch (this.target) {
            case '_blank':
              open(this.url)
              break
            case '_parent':
              parent.location = this.url
            case '_top':
              top.location = this.url
              break
            case '_self':
              location = this.url
              break
          }
        }
      }
      if (!json.noHoverPause) {
        obj.onmouseover = function () {
          clearInterval(this.t)
        }
        obj.onmouseout = function () {
          obj.t = setInterval(function () {
            that.float(obj.index)
          }, obj.interval)
        }
      }
      obj.src = json.src
      obj.style.cursor = json.cursor || this.cursor
      obj.style.position = 'absolute'
      obj.style.left = obj.left + 'px'
      obj.style.top = obj.top + 'px'
      obj.style.zIndex = json.zIndex || this.zIndex
      obj.style.width = json.width || ''
      obj.style.height = json.height || ''
      obj.style.directionX = json.directionX || this.directionX
      obj.style.directionY = json.directionY || this.directionY
      this.object.push(obj)
      //console.log(this.object)
      document.body.appendChild(obj)
      let that = this
      obj.t = setInterval(function () {
        that.float(obj.index)
      }, obj.interval)
    },
    float(index) {
      //console.log("dd",index); documentElement
      var pageWidth = document.body.clientWidth
      var pageHeight = document.body.clientHeight
      var scrollLeft = document.body.scrollLeft
      var scrollTop = document.body.scrollTop
      var obj = this.object[index]
      //console.log("s",obj)

      obj.left = obj.left + obj.directionX * obj.distance
      if (obj.left <= scrollLeft) {
        obj.left = scrollLeft
        obj.directionX = 1
      }
      if (obj.left + obj.offsetWidth >= pageWidth + scrollLeft - 1) {
        obj.left = pageWidth + scrollLeft - 1 - obj.offsetWidth
        obj.directionX = -1
      }

      obj.top = obj.top + obj.directionY * obj.distance
      if (obj.top <= scrollTop) {
        obj.top = scrollTop
        obj.directionY = 1
      }
      if (obj.top + obj.offsetHeight >= pageHeight + scrollTop - 1) {
        obj.top = pageHeight + scrollTop - 1 - obj.offsetHeight
        obj.directionY = -1
      }

      obj.style.left = obj.left + 'px'
      obj.style.top = obj.top + 'px'
    }
  },

  watch: {}
}
</script>
<style>
.floatLayer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: 1s linear;
}
.close-btn {
  /* display: block; */
  /* position: absolute; */
  /* left: 158px;
  top: -28px; */
  /* text-align: center; */
  overflow: hidden;
  z-index: 110;
  vertical-align: top;
  margin-top: 0px;
}
/* .close-btn:hover {
  color: #b50900;
  background: #ccc;
} */
.floatLayer img {
  /* position: relative; */
  z-index: 101;
}

.htmlCss {
  /* width: 100%;*/
  /* height: 100%; */
  overflow-y: auto;
  overflow-x: hidden;
}
.htmlCss img,
p,
span {
  max-width: 100% !important;
}
</style>