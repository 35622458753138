<!--
 * @Module wap端 侧边栏
 -->
<template>
  <el-drawer
    :visible.sync="drawer"
    :with-header="false"
    direction="ttb"
    size="100%"
    style="margin-top: 0.56rem"
    :modal="false"
  >
    <div class="drawer-box">
      <!-- <div class="drawer-title">
        <div class="title-box">登录</div>

        <div class="seat-line"></div>

        <div class="title-box">注册</div>
      </div> -->

      <div class="drawer-main">
        <div class="menu-item" :key="index" v-for="(item, index) in menuList">
           <!-- @click="firstMenu(item, index)" -->
          <div
            :style="{
              background:
                selectIndex1 === index && selectIndex2 === -1
                  ? '#409EFF'
                  : '#024B9D',
              color:
                selectIndex1 === index && selectIndex2 === -1 ? '#fff' : '#fff'
            }"
            class="item-box"
          >
            <div
              :style="{
                borderBottomWidth:
                  selectIndex1 === index && selectIndex2 === -1
                    ? '0.01rem'
                    : '0.01rem'
              }"
              class="item-box-border"
            >
              <div @click="onMenuItemClick(item)">{{ item.categoryTitle }}</div>
              <i
                @click="firstMenu(item, index)"
                v-if="
                  item.child && item.child.length > 0 && item.checked != true
                "
                class="el-icon-plus"
                style="
                  color: #fff;
                  font-weight: 500;
                  font-size: 0.2rem;
                  padding-left: 0.5rem;
                "
              ></i>
              <i
                @click="firstMenu(item, index)"
                v-if="
                  item.child && item.child.length > 0 && item.checked == true
                "
                class="el-icon-minus"
                style="
                  color: #fff;
                  font-weight: 500;
                  font-size: 0.2rem;
                  padding-left: 0.5rem;
                "
              ></i>
            </div>
          </div>

          <div v-show="item.checked" class="subMenu">
            <div class="subMenu-item" :key="i" v-for="(obj, i) in item.child">
              <div
                :style="{
                  background:
                    selectIndex1 === index &&
                    selectIndex2 === i &&
                    selectIndex3 === -1
                      ? '#409EFF'
                      : '#0E59AE',
                  color:
                    selectIndex1 === index &&
                    selectIndex2 === i &&
                    selectIndex3 === -1
                      ? '#fff'
                      : '#fff'
                }"
                class="subMenu-item-box"
                @click="onMenuItemClick(obj)"
              >
                <div>{{ obj.categoryTitle }}</div>
              </div>

              <div
                v-show="obj.child"
                class="subMenu-item"
                :key="s"
                v-for="(dataSet, s) in obj.child"
              >
                <div
                  :style="{
                    background:
                      selectIndex1 === index &&
                      selectIndex2 === i &&
                      selectIndex3 === s
                        ? '#409EFF'
                        : '#024B9D',
                    color:
                      selectIndex1 === index &&
                      selectIndex2 === i &&
                      selectIndex3 === s
                        ? '#fff'
                        : '#fff'
                  }"
                  class="subMenu-item-box"
                  @click="onMenuItemClick(dataSet)"
                >
                  <div>{{ dataSet.categoryTitle }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="drawer-search">
        <div class="search-box">
          <input type="text" @keyup.enter="searchKeyword" v-model="searchVal" />
          <div @click="searchKeyword">
            <img src="" alt="" />
          </div>
        </div>
      </div> -->
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'sidebar',
  data() {
    return {
      drawer: false,
      selectIndex1: 0,
      selectIndex2: -1,
      selectIndex3: -1,
      searchVal: '',
      menuData: []
    }
  },
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    // // 处理一级菜单选中状态
    // this.handleSelect()

    // 打开侧边栏
    this.$root.eventBus.$on('showDrawer', (res) => {
      if (res) {
        // 梳理整合数据
        this.drawer = res.drawer
      }
    })

    // 打开的详情页时获取的栏目id 进行数据整理
    this.$root.eventBus.$on('categoryId', (res) => {
      if (res) {
        // 梳理整合数据
        this.combination(res.categoryId)
      }
    })
  },
  methods: {
    /**
     * 展示侧边栏
     */
    showDrawer() {
      this.drawer = true
    },
    /**
     * 搜索关键字
     */
    searchKeyword() {
      this.drawer = false
      this.naviGate('search', {
        t: new Date().getTime(),
        search: this.searchVal
      })
    },
    /**
     * 处理一级菜单选中状态
     */
    handleSelect() {
      const {
        categoryId = null,
        id = null,
        contentId = null
      } = this.$route.query

      // 首页
      if (!categoryId && !id && !contentId) {
        this.selectIndex1 = 0
        this.selectIndex2 = -1
        this.selectIndex3 = -1
        return
      }

      if (this.menuList) {
        if (!contentId) {
          // 代表打开的是列表页
          if (categoryId) {
            // 代表子菜单的点击 (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击) 梳理整合数据
            this.combination(categoryId, id)
          } else {
            // 代表一级菜单的点击 (无子菜单的一级菜单) 梳理整合数据
            this.combination('', id)
          }
        }
      }
    },
    /**
     * 梳理整合数据
     * @param {categoryId} 父级id
     * @param {id} 本身id
     */
    combination(categoryId, id) {
      categoryId = parseInt(categoryId) ? parseInt(categoryId) : ''
      id = parseInt(id) ? parseInt(id) : ''

      // 获取父级菜单的详情数据
      let upLevelMenu = {}
      if (categoryId && categoryId !== '') {
        upLevelMenu = this.categoryList
          .filter((item) => item.id === categoryId)
          .pop()
      } else {
        upLevelMenu = this.categoryList.filter((item) => item.id === id).pop()
      }

      if (upLevelMenu) {
        if (!upLevelMenu.categoryId || upLevelMenu.categoryId === '') {
          // 代表打开的为第二级页面内容
          this.menuList.forEach((item, index) => {
            if (categoryId && categoryId !== '') {
              // 第二级子菜单点击打开内容时会传categoryId categoryId为第一级菜单的id (有子菜单的一级菜单 点击等同二级菜单第一个菜单点击)
              if (item.id === categoryId) {
                this.selectIndex1 = index
                item.checked = true
                if (item.child) {
                  item.child.forEach((obj, i) => {
                    if (id && id !== '') {
                      if (obj.id === id) {
                        this.selectIndex2 = i
                        this.selectIndex3 = -1
                      }
                    }
                  })
                }
              }
            } else {
              // 无子菜单的一级菜单点击打开内容时不传categoryId 以id进行数据匹配
              if (item.id === id) {
                this.selectIndex1 = index
              }
            }
          })
        } else {
          // 代表打开的为第三级页面内容 categoryId为第二级菜单的id
          this.menuList.forEach((item, index) => {
            if (item.child) {
              item.child.forEach((obj, i) => {
                if (obj.id === categoryId) {
                  item.checked = true
                  this.selectIndex1 = index
                  this.selectIndex2 = i
                  this.selectIndex3 = -1

                  if (obj.child) {
                    obj.child.forEach((dataSet, s) => {
                      if (id && id !== '') {
                        if (dataSet.id === id) {
                          this.selectIndex3 = s
                        }
                      }
                    })
                  }
                }
              })
            }
          })
        }
      }
    },
    /**
     * 第一层菜单点击
     */
    firstMenu(item, index) {
      if (item.child && item.child.length > 0) {
        item.checked = !item.checked
        this.$set(this.menuList, index, item)
      } else {
        this.onMenuItemClick(item)
      }
    },
    /**
     * 菜单点击
     */
    onMenuItemClick(item) {
      this.drawer = false
      this.$store.commit('setSideBarStatus', false)
      this.$emit('getmenu', item)
    }
  },
  watch: {
    $route(route) {
      const { categoryId = null, id = null, contentId = null } = route.query

      if (!categoryId && !id && !contentId) {
        this.selectIndex1 = 0
        this.selectIndex2 = -1
        this.selectIndex3 = -1
        return
      }

      // 处理一级菜单选中状态
      this.handleSelect()
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .drawer-title {
    width: 100%;
    height: 0.52rem;
    display: flex;
    align-items: center;
    background: #183878;

    .title-box {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.52rem;
      color: #fff;
      font-size: 0.17rem;
      font-weight: 500;
    }

    .seat-line {
      width: 0.01rem;
      height: 0.29rem;
      background: #3865c1;
    }
  }

  .drawer-main {
    flex: 1;
    width: 100%;
    overflow: auto;
    background: #024b9d;
    .menu-item {
      width: 100%;

      .item-box {
        width: 100%;
        height: 0.5rem;
        font-size: 0.17rem;
        color: #455b78;
        // padding: 0 0.17rem;

        &-border {
          // width: 100%;
          padding: 0 0.17rem;
          height: 100%;
          display: flex;
          align-items: center;
          border-bottom: 0.01rem solid #1762b8;

          > div {
            flex: 1;
            margin-left: 0.03rem;
          }
        }
      }

      .subMenu {
        width: 100%;
        background: #024b9d;
        &-item {
          width: 100%;
          &-box {
            // width: 100%;
            // padding-left: 0 0.3rem;
            height: 0.4rem;
            display: flex;
            align-items: center;
            font-size: 0.14rem;

            color: #fff;
            // border-bottom: 0.01rem solid #eeeeee;

            > div {
              margin-left: 0.3rem;
            }
          }
        }
      }
    }
  }

  .drawer-search {
    width: 100%;
    padding: 0.16rem 0.14rem 0.18rem 0.17rem;

    .search-box {
      display: flex;
      align-items: center;

      input {
        width: 1.82rem;
        height: 0.42rem;
        background: #eeeeee;
        font-size: 0.15rem;
        border: none;
        padding-left: 0.2rem;
        font-size: 0.14rem;
        color: #333333;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0.45rem;
        height: 0.42rem;
        background: #f6ad3d;
        margin-left: -0.01rem;
        cursor: pointer;

        img {
          width: 0.38rem;
          height: auto;
          align-self: center;
        }
      }
    }
  }
}
</style>
