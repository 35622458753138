<!--
 * @Module  焦点图
 -->

<template>
  <div :class="[isPc ? '' : 'wapBanner', 'banner']">
    <div class="block" style="position: relative !important">
      <div
        style="position: absolute;left: 40px;top: 40%;transform: translate(-40%);z-index: 9999"
        @click="arrowClick('left')"
        v-if="carouselList.length > 1 && isPc"
      >
        <img
          src="@/assets/images/common/icon_banner_back.png"
          alt=""
          style="width: 60px; height: 60px"
        />
      </div>
      <el-carousel
        loop
        :indicator-position="isPc? 'none' : ''"
        arrow="never"
        ref="carouselBox"
      >
        <el-carousel-item
          v-for="(item, index) in carouselList"
          :key="index"
          @click.native="handleRow(item)"
        >
          <img
            :src="handleUrl(item.contentImg)"
            :alt="item.contentTitle"
            style="object-fit: cover !important"
          />
        </el-carousel-item>
      </el-carousel>
      <div
        style="
          position: absolute;
          right: 0px;
          top: 40%;
          transform: translate(-40%);
          z-index: 9999;
        "
        @click="arrowClick('right')"
        v-if="carouselList.length > 1 && isPc"
      >
        <img
          src="@/assets/images/common/icon_banner_forward.png"
          alt=""
          style="width: 60px; height: 60px"
        />
      </div>
    </div>
  </div>
</template>

<!--JavaScript-->
<script>
import { mapState } from 'vuex'
import { convertImgUrl } from '@/utils/index'

export default {
  name: 'Banner',
  props: { carouselList: Array },
  computed: {
    ...mapState(['isPc'])
  },
  methods: {
    // 当前行的跳转逻辑
    handleRow(row) {
      if (row.contentUrl) {
        // 外链
        window.open(row.contentUrl)
      } else {
        // 内链 todo
        console.log('内链')
      }
    },
    /**
     * @description: 拼接图片地址
     * @param {string} url 图片地址
     */
    handleUrl(url) {
      return convertImgUrl(url)
    },

    arrowClick(val) {
      console.log(this.$refs.carouselBox)
      if (val == 'right') {
        this.$refs.carouselBox.next()
      } else {
        this.$refs.carouselBox.prev()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  // height: 21.875vw;
  height: 41.716vh;
  @media screen and (max-width: 820px) {
    height: 1.12rem;
  }

  img {
    width: 100%;
    height: 100%;
  }

  ::deep .el-carousel__container {
    // height: 21.875vw;
    height: 41.716vh;
    @media screen and (max-width: 820px) {
      height: 1.12rem;
    }
  }

  /* ::deep {
    .el-carousel__arrow {
      width: 60px;
      height: 60px;
      border: 2px solid rgba(255, 255, 255, 0.6);
    }
  } */

  &.wapBanner {
    .block {
      ::deep {
        .el-carousel__container {
          height: 1.2rem;
          @media screen and (max-width: 820px) {
            height: 1.12rem;
          }
        }
        .el-carousel__indicator, .el-carousel__button {
          cursor: none;
        }
        img {
          // height: 1.2rem;
          height: 100%;
        }
      }
    }
  }
}
</style>
